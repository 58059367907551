import React from "react"
import { ColorPalette } from "../Theme"
import Accordion from "react-bootstrap/Accordion"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const InterviewChecklistSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pb-5 pt-0"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="contentHeroSpace hidden-xs"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#365c70",
          }}
        >
          Interview Checklist
        </h2>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-5 mb-5"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
          }}
        />

        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <ul class="list-group">
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Make time to research Norhart
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Study the job description
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Practice interviewing
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Write down questions for your interview
                </li>
              </ul>
            </div>

            <div class="col-lg-6">
              <ul class="list-group">
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Mentally prepare for the interview
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Be your best self
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Bring essentials to your intreview
                </li>
                <li class="list-group-item">
                  <input class="form-check-input me-1" type="checkbox" value="" checked />
                  {""} Send a thank you note
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default InterviewChecklistSection
