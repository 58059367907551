import React from "react"
import { ColorPalette } from "../Theme"
import Accordion from "react-bootstrap/Accordion"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const FAQInterviewSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pb-5 pt-0"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="contentHeroSpace hidden-xs"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#365c70",
          }}
        >
          Frequently Asked Questions
        </h2>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-5 mb-5"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
          }}
        />

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              When should I expect to hear back from my recruiter after my final interview?
            </Accordion.Header>
            <Accordion.Body>
              Depending on the role and timeline to hire, your recruiter will keep you in the loop on potential next
              steps.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Can I apply for multiple jobs?</Accordion.Header>
            <Accordion.Body>
              There are no limits to the amount of jobs you can apply for, however we ask that you use good judgement
              and apply to the select roles you feel you are most qualified for and interested in. Our recruiting team
              works very closely with our business leaders and can recommend other roles throughout your interview
              experience, if appropriate.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Can I expect to receive feedback during my interview journey?</Accordion.Header>
            <Accordion.Body>
              Yes, we believe we will learn faster and be better if we can make giving and receiving feedback a regular
              part of our working relationships. Feedback is a key pillar of our culture and it begins during the
              interview journey.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>What if I need an accommodation for my interview?</Accordion.Header>
            <Accordion.Body>
              Inclusion is a Norhart value. It is important to us that we host a meaningful experience that is mindful
              of the various considerations needed in order for every person to show up as their best selves. We also
              recognize that your needs may change at any point in time. Should you need any support or accommodations
              leading up to or during your conversation(s), please don’t hesitate to reach out to your Talent
              Coordinator and/or Recruiting Partner.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  )
}

/** export */
export default FAQInterviewSection
