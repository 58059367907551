import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import FAQInterviewSection from "../components/static-sections/FAQInterviewSection"
import InterviewChecklistSection from "../components/static-sections/InterviewChecklistSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import GoogleMapSection from "../components/dynamic-sections/GoogleMapSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import FourStepSection from "../components/dynamic-sections/FourStepSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const InterviewProcessPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/interview-process/",
          name: "Interview Process | Norhart",
          image: `${config.siteMetadata.siteUrl}/interview-process/norhart-interview-process-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Interview Process | Norhart"
      description="Throughout your interview journey, you will meet potential colleagues to discuss the expectations of the role, learn about their unique Norhart experience and how the culture is in effect daily."
      keywords="norhart, benefits, free benefits, employee benefits, health care, dental, vision, legal, maternity, paternity, childcare, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/interview-process/norhart-interview-process-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/interview-process/norhart-interview-process-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title={"Interview Process"}
        subtitle="How Our Process Works"
        imageTitle="Norhart Interview Process"
        image="/interview-process/norhart-interview-process-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart Interview Process"
        tagLine="Prepare for your upcoming Interview "
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Leadership"
        title="Meet the Crew"
        subTitle="Norhart hires literally the best talent in the world! Our culture is built around respect, reliability, and results from the top down. Our team believes in a can-do attitude with a grow-or-die mindset. See if you have what it takes to be one of us!"
        image="/interview-process/norhart-interview-process-meet-the-team.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <FourStepSection
        backgroundColorOne={colorPalette.heroBottom}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Interview"
        title="Your Interview Journey"
        subTitle="Make sure you bring your best self to your interview. If you are selected, you will go through our 6-step process below, with feedback and guidance every step of the way. #GoodLuck"
        imageOne="/interview-process/norhart-interview-process-step-1.png"
        imageOneAlt="Norhart Interview Process Step 1"
        imageTwo="/interview-process/norhart-interview-process-step-2.png"
        imageTwoAlt="Norhart Interview Process Step 2"
        imageThree="/interview-process/norhart-interview-process-step-3.png"
        imageThreeAlt="Norhart Interview Process Step 3"
        imageFour="/interview-process/norhart-interview-process-step-4.png"
        imageFourAlt="Norhart Interview Process Step 4"
        colorPalette={colorPalette}
        buttonName="Job Listings"
        buttonUrl="/careers/"
      />

      <InterviewChecklistSection />

      <FAQInterviewSection />

      <GoogleMapSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Forest Lake, MN"
        title="Onsite Interviews"
        subtitle="Some roles will require an onsite interview at Norhart HQ. During your in-person interview, our team will decide if you are a good fit for the role, our values, and our culture."
        query="norhart+forest+lake+,+MN"
        colorPalette={colorPalette}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart"
        title="Welcome To The Team!"
        subTitle="Kill the interview, align with our values, and be the perfect fit for the role, and you will be one of us! Your future colleagues are looking forward to working with you! #TeamNorhart #FistBump"
        image="/interview-process/norhart-interview-process-be-one-of-us.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        asoEffect="zoom-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default InterviewProcessPage
