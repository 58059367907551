import React from "react"

/** const */
const Awards: React.FC = () => {
  let images = [
    "norhart-award-hero-1.png",
    "norhart-award-hero-2.png",
    "norhart-award-hero-3.png",
    "norhart-award-hero-4.png",
    "norhart-award-hero-5.png",
  ]

  let image = images[Math.floor(Math.random() * images.length)]

  return (
    <div>
      <span>
        <svg
          className="award"
          viewBox="0 0 211 102"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
        >
          <path fill="none" d="M0 0h210.15v101.156H0z" />
          <g fill="#fff" fillRule="nonzero">
            <path d="M20.155 38.741s3.432-4.472 1.212-10.682c-2.227-6.205-7.413-8.562-7.413-8.562s-3.88 5.328-1.712 11.377c2.165 6.053 7.913 7.866 7.913 7.866v.001zm9.524 3.174s6-.486 9.435-5.908c3.436-5.42.81-11.48.81-11.48s-5.57 1.144-9.098 6.707c-3.525 5.56-1.147 10.68-1.147 10.68v.001zm.608 7.164c-2.777 5.98.24 10.747.24 10.747s5.892-1.276 8.595-7.098c2.7-5.83-.686-11.49-.686-11.49s-5.372 1.863-8.15 7.84l.001.001zm13.56 4.816s-5.178 2.372-7.386 8.582c-2.208 6.22 1.236 10.676 1.236 10.676s5.74-1.828 7.893-7.885c2.15-6.057-1.742-11.373-1.742-11.373zm4.315 32.698s4.98-3.425 5.318-9.85c.334-6.43-4.914-10.383-4.914-10.383s-4.284 3.777-4.63 10.37c-.34 6.59 4.227 9.862 4.227 9.862v.001zM27.805 26.775s5.31-1.793 6.867-8.202c1.56-6.41-1.457-11.28-1.457-11.28s-6.15 2.256-7.672 8.508c-1.52 6.25 2.262 10.974 2.262 10.974zm-7.654 25.593s1.972-5.297-1.96-10.563c-3.937-5.27-9.577-5.978-9.577-5.978s-2.153 6.248 1.68 11.385c3.836 5.14 9.858 5.157 9.858 5.157zm4.4 13.977s.755-5.607-4.233-9.858c-4.997-4.256-10.652-3.676-10.652-3.676s-.727 6.576 4.137 10.722c4.873 4.142 10.748 2.81 10.748 2.81v.002zm6.701 11.556s-.95-5.574-6.984-8.095c-6.04-2.53-11.252-.247-11.252-.247s1.278 6.49 7.158 8.958c5.88 2.454 11.077-.615 11.077-.615h.001zm2.927 4.8c-6.29-1.777-11.2 1.117-11.2 1.117s2.033 6.287 8.16 8.02c6.136 1.736 10.93-1.94 10.93-1.94s-1.6-5.423-7.89-7.196z" />
            <path d="M28.46 30.596l-2.797-1.39c-.147.31-14.532 31.196 25.888 66.122l2.022-2.392c-38.484-33.26-25.68-61.17-25.114-62.34z" />
          </g>
          <g fill="#fff" fillRule="nonzero">
            <path d="M191.994 38.681s-3.436-4.47-1.213-10.677c2.22-6.21 7.41-8.566 7.41-8.566s3.877 5.325 1.708 11.377c-2.158 6.053-7.905 7.866-7.905 7.866zm-9.525 3.175s-6-.49-9.438-5.908c-3.436-5.418-.81-11.48-.81-11.48s5.57 1.144 9.102 6.704c3.525 5.564 1.147 10.685 1.147 10.685zm-.607 7.161c2.777 5.978-.24 10.746-.24 10.746s-5.892-1.276-8.6-7.098c-2.695-5.83.687-11.49.687-11.49s5.375 1.867 8.153 7.842zm-13.56 4.82s5.174 2.37 7.386 8.59c2.206 6.216-1.24 10.671-1.24 10.671s-5.737-1.832-7.89-7.89c-2.154-6.055 1.743-11.372 1.743-11.372h.001zM163.98 86.53s-4.977-3.422-5.314-9.85c-.333-6.43 4.92-10.387 4.92-10.387s4.275 3.778 4.624 10.375c.344 6.596-4.23 9.862-4.23 9.862zm20.357-59.815s-5.302-1.793-6.86-8.202c-1.56-6.41 1.46-11.283 1.46-11.283s6.152 2.255 7.67 8.512c1.518 6.244-2.27 10.973-2.27 10.973zM192 52.31s-1.973-5.297 1.955-10.563c3.94-5.27 9.582-5.978 9.582-5.978s2.157 6.248-1.68 11.385c-3.84 5.14-9.858 5.156-9.858 5.156H192zm-4.4 13.977s-.76-5.606 4.232-9.866c4.996-4.253 10.65-3.673 10.65-3.673s.73 6.58-4.14 10.723c-4.868 4.142-10.743 2.815-10.743 2.815l.001.001zm-6.706 11.557s.953-5.58 6.987-8.104c6.044-2.53 11.253-.247 11.253-.247s-1.278 6.492-7.158 8.955c-5.883 2.467-11.08-.603-11.08-.603h-.002zm-2.928 4.8c6.29-1.774 11.205 1.116 11.205 1.116s-2.028 6.283-8.16 8.03c-6.13 1.726-10.926-1.95-10.926-1.95s1.596-5.42 7.882-7.196z" />
            <path d="M183.686 30.538l2.804-1.39c.148.31 14.533 31.195-25.89 66.117l-2.02-2.392c38.477-33.25 25.672-61.17 25.107-62.335z" />
          </g>
          <text
            x="181.287"
            y="29.8"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="12"
            fill="#fff"
            transform="translate(-92.626 -.933)"
          >
            BEST
          </text>
          <text
            x="179.411"
            y="49.068"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="19.745"
            fill="#fff"
            transform="translate(-105.212 -.464)"
          >
            GLOBE
          </text>
          <text
            x="185.229"
            y="72.585"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="19.745"
            fill="#fff"
            transform="translate(-120.558 -1.804)"
          >
            A
            <tspan x="197.666px 215.569px 228.739px" y="72.585px 72.585px 72.585px">
              WAR
            </tspan>
            DS
          </text>
          <text
            x="182.376"
            y="83.512"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="11"
            fill="#fff"
            transform="translate(-105 3)"
          >
            GOLD 2020
          </text>
        </svg>
      </span>

      <span>
        <svg
          className="award"
          viewBox="0 0 211 102"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
        >
          <path fill="none" d="M0 0h210.15v101.156H0z" />
          <g fill="#fff" fillRule="nonzero">
            <path d="M20.155 38.741s3.432-4.472 1.212-10.682c-2.227-6.205-7.413-8.562-7.413-8.562s-3.88 5.328-1.712 11.377c2.165 6.053 7.913 7.866 7.913 7.866v.001zm9.524 3.174s6-.486 9.435-5.908c3.436-5.42.81-11.48.81-11.48s-5.57 1.144-9.098 6.707c-3.525 5.56-1.147 10.68-1.147 10.68v.001zm.608 7.164c-2.777 5.98.24 10.747.24 10.747s5.892-1.276 8.595-7.098c2.7-5.83-.686-11.49-.686-11.49s-5.372 1.863-8.15 7.84l.001.001zm13.56 4.816s-5.178 2.372-7.386 8.582c-2.208 6.22 1.236 10.676 1.236 10.676s5.74-1.828 7.893-7.885c2.15-6.057-1.742-11.373-1.742-11.373zm4.315 32.698s4.98-3.425 5.318-9.85c.334-6.43-4.914-10.383-4.914-10.383s-4.284 3.777-4.63 10.37c-.34 6.59 4.227 9.862 4.227 9.862v.001zM27.805 26.775s5.31-1.793 6.867-8.202c1.56-6.41-1.457-11.28-1.457-11.28s-6.15 2.256-7.672 8.508c-1.52 6.25 2.262 10.974 2.262 10.974zm-7.654 25.593s1.972-5.297-1.96-10.563c-3.937-5.27-9.577-5.978-9.577-5.978s-2.153 6.248 1.68 11.385c3.836 5.14 9.858 5.157 9.858 5.157zm4.4 13.977s.755-5.607-4.233-9.858c-4.997-4.256-10.652-3.676-10.652-3.676s-.727 6.576 4.137 10.722c4.873 4.142 10.748 2.81 10.748 2.81v.002zm6.701 11.556s-.95-5.574-6.984-8.095c-6.04-2.53-11.252-.247-11.252-.247s1.278 6.49 7.158 8.958c5.88 2.454 11.077-.615 11.077-.615h.001zm2.927 4.8c-6.29-1.777-11.2 1.117-11.2 1.117s2.033 6.287 8.16 8.02c6.136 1.736 10.93-1.94 10.93-1.94s-1.6-5.423-7.89-7.196z" />
            <path d="M28.46 30.596l-2.797-1.39c-.147.31-14.532 31.196 25.888 66.122l2.022-2.392c-38.484-33.26-25.68-61.17-25.114-62.34z" />
          </g>
          <g fill="#fff" fillRule="nonzero">
            <path d="M191.994 38.681s-3.436-4.47-1.213-10.677c2.22-6.21 7.41-8.566 7.41-8.566s3.877 5.325 1.708 11.377c-2.158 6.053-7.905 7.866-7.905 7.866zm-9.525 3.175s-6-.49-9.438-5.908c-3.436-5.418-.81-11.48-.81-11.48s5.57 1.144 9.102 6.704c3.525 5.564 1.147 10.685 1.147 10.685zm-.607 7.161c2.777 5.978-.24 10.746-.24 10.746s-5.892-1.276-8.6-7.098c-2.695-5.83.687-11.49.687-11.49s5.375 1.867 8.153 7.842zm-13.56 4.82s5.174 2.37 7.386 8.59c2.206 6.216-1.24 10.671-1.24 10.671s-5.737-1.832-7.89-7.89c-2.154-6.055 1.743-11.372 1.743-11.372h.001zM163.98 86.53s-4.977-3.422-5.314-9.85c-.333-6.43 4.92-10.387 4.92-10.387s4.275 3.778 4.624 10.375c.344 6.596-4.23 9.862-4.23 9.862zm20.357-59.815s-5.302-1.793-6.86-8.202c-1.56-6.41 1.46-11.283 1.46-11.283s6.152 2.255 7.67 8.512c1.518 6.244-2.27 10.973-2.27 10.973zM192 52.31s-1.973-5.297 1.955-10.563c3.94-5.27 9.582-5.978 9.582-5.978s2.157 6.248-1.68 11.385c-3.84 5.14-9.858 5.156-9.858 5.156H192zm-4.4 13.977s-.76-5.606 4.232-9.866c4.996-4.253 10.65-3.673 10.65-3.673s.73 6.58-4.14 10.723c-4.868 4.142-10.743 2.815-10.743 2.815l.001.001zm-6.706 11.557s.953-5.58 6.987-8.104c6.044-2.53 11.253-.247 11.253-.247s-1.278 6.492-7.158 8.955c-5.883 2.467-11.08-.603-11.08-.603h-.002zm-2.928 4.8c6.29-1.774 11.205 1.116 11.205 1.116s-2.028 6.283-8.16 8.03c-6.13 1.726-10.926-1.95-10.926-1.95s1.596-5.42 7.882-7.196z" />
            <path d="M183.686 30.538l2.804-1.39c.148.31 14.533 31.195-25.89 66.117l-2.02-2.392c38.477-33.25 25.672-61.17 25.107-62.335z" />
          </g>
          <text
            x="181.287"
            y="29.8"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="12"
            fill="#fff"
            transform="translate(-94.94 -1.582)"
          >
            SIL
            <tspan x="198.408px 206.412px" y="29.8px 29.8px">
              VE
            </tspan>
            R
          </text>
          <text
            x="179.411"
            y="49.068"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="19.745"
            fill="#fff"
            transform="translate(-123.26 -.164)"
          >
            AMERICAN
          </text>
          <text
            x="185.229"
            y="72.585"
            fontFamily="'ArialMT','Arial',sans-serif"
            fontSize="9"
            fill="#fff"
            transform="translate(-121.063 -12.855)"
          >
            BUSINESS{" "}
            <tspan x="232.747px 238.416px 246.577px 252.58px" y="72.585px 72.585px 72.585px 72.585px">
              AWAR
            </tspan>
            DS
          </text>
          <g fontFamily="'ArialMT','Arial',sans-serif" fontSize="11" fill="#fff">
            <text x="182.376" y="83.512" transform="translate(-108.135 -6.523)">
              2020 STEVIE
            </text>
            <text x="193.398" y="94.872" transform="translate(-108.135 -6.523)">
              WINNER
            </text>
          </g>
        </svg>
      </span>
      <div className="container pt-5">
        <div className="col-sm-12">
          <img
            src={`/awards/${image}`}
            width="800"
            height="400"
            loading="lazy"
            decoding="async"
            alt="Norhart Award Winning Apartments In Forest Lake, Blaine and Oakdale"
            className="mw-100 h-auto"
          />
        </div>
      </div>
    </div>
  )
}

/** export */
export default Awards
