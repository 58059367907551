import React from "react"
import { ColorPalette } from "../Theme"

/** components */
import Awards from "./Awards"

/** svg */
import ApartmentsIcon from "../../../assets/apartment-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  header: string
  title: string
  subtitle: string
  colorPalette: ColorPalette
  fontTextColor?: string
}

/** const */
const AwardsSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0 text-center"
      style={{
        background: `linear-gradient(${props.colorPalette.heroTop} 30%, ${props.colorPalette.heroBottom} 70%)`,
        color: "#FFFFFF",
      }}
    >
      <div className="container pl-2 pr-2 text-center">
        <div>
          <div className="text-center pb-2">
            <NorhartDarkCircleIcon />
          </div>
          <div
            className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl fs-6"
            style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
          >
            {props.header}
          </div>
          <hr
            className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
            style={{ borderColor: "rgba(0, 0, 0, 0.2)", color: `${props.fontTextColor}` }}
          />
          <h3 className="contentHeroTitle" style={{ color: `${props.fontTextColor}` }}>
            {props.title}
          </h3>
          <h4 className="contentHeroSubTitle opacity-75" style={{ color: "#fff", opacity: "0.7" }}>
            {props.subtitle}
          </h4>
          <Awards />
        </div>
      </div>
    </section>
  )
}

/** export */
export default AwardsSection
